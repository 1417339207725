import React from 'react'
import { Link } from 'react-router-dom'
import "./style.css";

function Footer(props) {
    return (
        <div className="footer-container">
            <div className="footer-section">
                <Link className="logo-container" to="/" style={{ display: 'flex', height: '100%' }}>
                    <img
                        src="/images/logojvmpnoback.png" // Remplacez par l'URL de votre logo
                        alt="Logo"
                        style={{ transform: 'scale(6.5)' }}
                        className="logo"
                    />

                </Link>
            </div>
            <div className="footer-section">
                <p className="footer-section-title">À propos</p>
                <ul className="footer-section-list">
                    <li className="footer-section-list-item">
                        <a href="https://jevendsmonpotager.fr/qui-somme-nous/" >Qui sommes-nous ?</a>
                    </li>
                    <li className="footer-section-list-item">
                        <a href="https://jevendsmonpotager.fr/blog/" >Blog</a>
                    </li>
                    <li className="footer-section-list-item">
                        <a href="https://jevendsmonpotager.fr/nous-contacter/" >Nous contacter</a>
                    </li>
                </ul>

            </div>
            <div className="footer-section">
                <p className="footer-section-title">Informations Légales</p>
                <ul className="footer-section-list">
                    <li className="footer-section-list-item">
                        <a href="https://jevendsmonpotager.fr/cgu/" >CGU</a>
                    </li>
                    <li className="footer-section-list-item">
                        <a href="https://jevendsmonpotager.fr/politique-de-confidentialite/" >Politique de confidentialité</a>
                    </li>
                    <li className="footer-section-list-item">
                        <a href="https://jevendsmonpotager.fr/mentions-legales/" >Mention légales</a>
                    </li>
                </ul>
            </div>
            <div className="footer-section">
                <p className="footer-section-title">Nous suivre</p>
                <ul className="footer-section-icons">
                    <li className="footer-section-list-item">
                        <a href="https://www.facebook.com/jevendsmonpotager/" ><img
                            src="/images/facebook.png"
                            alt="Facebook"
                        /></a>
                    </li>
                    <li className="footer-section-list-item">
                        <a href="https://pin.it/5RB4ntH" ><img
                            src="https://jevendsmonpotager.fr/wp-content/uploads/2023/07/Pinterest-logo.png"
                            alt="Pinterest"
                        /></a>
                    </li>


                    <li className="footer-section-list-item">
                        <a href="https://www.instagram.com/jevendsmonpotager.fr/" ><img
                            src="/images/instagram.png"
                            alt="Instagram"
                        /></a>
                    </li>
                </ul>
            </div>
        </div >

    )
}

export default Footer
